@keyframes wave {
  0% {
    transform: translateX(0) translateY(0);
  }
  50% {
    transform: translateX(-25%) translateY(10px);
  }
  100% {
    transform: translateX(-50%) translateY(0);
  }
}

.wave {
  position: absolute;
  width: 200%;
  height: 100%;
  background: #a0d8ef; /* 파도의 색상 */
  opacity: 0.6; /* 파도의 투명도 */
  bottom: 0;
  left: 0;
  z-index: -1;
  transform-origin: center bottom;
  animation: wave 5s infinite linear;
}

.wave:nth-child(2) {
  opacity: 0.4;
  animation: wave 10s infinite linear;
  z-index: -2;
}

.wave:nth-child(3) {
  opacity: 0.2;
  animation: wave 15s infinite linear;
  z-index: -3;
}

.wave-container {
  position: relative;
  width: 100%;
  height: 100vh; /* 전체 화면 높이를 채우도록 설정 */
  overflow: hidden; /* 컨텐츠가 넘치지 않도록 설정 */
  background: #f0f8ff; /* 배경색 설정 */
}
